<template>
    <el-table v-if="damagesData.length > 0" :data="damagesData" style="width: 100%">
        <el-table-column :label="$t('employees.address')" :show-overflow-tooltip="true">
            <template slot-scope="scope">
                {{ scope.row.street }}, {{ scope.row.city }} {{ scope.row.post_code }}
            </template>
        </el-table-column>
        <el-table-column :label="$t('employee_details.client')">
            <template slot-scope="scope">
                <router-link :to="`/clients/${scope.row.client_uuid}/dashboard`" class="hover:text-red-500 hover:font-semibold">
                    {{ scope.row.client_name }} {{ scope.row.client_surname }}
                </router-link>
            </template>
        </el-table-column>
        <el-table-column prop="description" :label="$t('employees.description')">
            <template slot-scope="scope">
                <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="scope.row.description" placement="top" popper-class="w-112">
                    <p>
                        {{ scope.row.description && scope.row.description.length > 15
                            ? scope.row.description.slice(0, 15) + '...' : scope.row.description }}
                    </p>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="amount" :label="$t('employees.amount')">
            <template slot-scope="scope">
                {{ intToMoneyFormat(scope.row.amount) }}
            </template>
        </el-table-column>
        <el-table-column prop="status" :label="$t('employees.status')" width="60">
            <template slot-scope="scope">
                {{ scope.row.status === 0 ? $t('employees.open') : $t('employees.solved') }}
            </template>
        </el-table-column>
        <el-table-column prop="created_at" width="80" />
        <el-table-column width="80">
            <template slot-scope="scope">
                <el-button-group>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.edit')" placement="top">
                        <el-button type="primary" size="mini" @click="handleEdit(scope.row)">
                            <fa-icon :icon="['far', 'edit']" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('employees.delete')" placement="top">
                        <el-button type="danger" size="mini" @click="handleDelete(scope.row.uuid)">
                            <fa-icon :icon="['fas', 'trash']" fixed-width />
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
export default {
    props: {
        damagesData: {
            type:    Array,
            default: () => [],
        },
    },

    methods: {
        handleEdit(item) {
            this.$emit('handleEdit', item);
        },

        handleDelete(uuid) {
            this.$emit('handleDelete', uuid);
        },
    },
};
</script>
