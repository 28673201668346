<template>
    <el-table v-if="servicesCategoryData.length > 0" :data="servicesCategoryData" style="width: 100%">
        <el-table-column prop="name" :label="$t('employee_details.category')">
            <template slot-scope="scope">
                {{ scope.row.name }}
            </template>
        </el-table-column>
        <el-table-column width="45">
            <template slot-scope="scope">
                <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.delete')" placement="top">
                    <el-button type="danger" size="mini" @click="handleDelete(scope.row.uuid)">
                        <fa-icon :icon="['fas', 'trash']" fixed-width />
                    </el-button>
                </el-tooltip>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
export default {
    props: {
        servicesCategoryData: {
            type:    Array,
            default: () => [],
        },
    },

    methods: {
        handleDelete(item) {
            this.$emit('handleDelete', item);
        },
    },
};
</script>
